<template>
  <div>
    <div class="container container-global">
      <div class="wrap-form">
          <div class="form-forgot main-overlay-np">
              <div class="title-form">
                  <h1>Reset your password</h1>
                  <div class="text-center">Please enter and confirm your new password below and click continue.<br>You will then receive an email to confirm that your password has been successfully change. <br></div>
                  <!-- <p class="text-left">Type the address linked to your account and we'll send you password reset instructions.</p> -->
              </div>
              <AlertBox v-if="message.text" :message="message.text" :variant="message.type"/>   
              <form method="post" @submit.prevent="submitForm">
                  <div class="form-group form-group-cs mb-3">                   
                    <input type="password" class="form-control form-cs" v-model="password" placeholder="New password" required />
                  </div>
                  <div class="form-group form-group-cs mb-3">                   
                    <input type="password" class="form-control form-cs" v-model="password_confirmation" placeholder="Confirm new password" required />
                  </div>
                    
                  <div class="text-center mt-4">
                    <button type="submit" class="btn btn-orange" style="width: 180px;">Submit</button>
                  </div>
                  <div v-if="load" class="overlay">
                    <div class="loadding_circle"></div>
                  </div>
              </form>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import AlertBox from "../../components/Layout/AlertBox.vue";
import { isLogin, homePage } from "../../utils";

export default {
    name: "Login",
    components: {
        AlertBox,
    },
    setup(){
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        if (isLogin()) {       
            router.push(homePage()); 
        }

        if(!route.query.token){
            router.push('/login');
        }
         
        const password = ref("")
        const password_confirmation = ref("")
        const load = ref(false)
        const message = reactive({
                text : "",
                type : "success"
        })
        const token = route.query.token
        console.log(token)

        const submitForm = () => {
            
            message.text = ""
            if(password.value !== password_confirmation.value){
                message.text = "The password confirmation does not match."
                message.type = "danger";
                return false
            }
            load.value = true
            
            store.dispatch("auth/reset_password", {password : password.value, password_confirmation : password_confirmation.value, token : token} ).then(
                (res) => {
                    console.log(res)
                    message.text = res.message;
                    message.type = "success"
                    password.value = ""
                    password_confirmation.value = ""
                    load.value = false
                },
                (error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        message.text = error.response.data.message;
                    }else{
                        message.text = error
                    }
                    password.value = ""
                    password_confirmation.value = ""
                    message.type = "danger"
                    load.value = false                          
                }
            );
        }
        return { password, password_confirmation, submitForm, load, message }
    } 
    
  
     
};
</script>

<style scoped>
.form-forgot {
    max-width: 480px;
    margin: auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 10%);   
}
</style>